.c-main {
    width: 100%;
    padding-top: 0;
    @include media-breakpoint-up(xs) {
        margin-top: 0px;
    }
}

.c-mainBox {
    display: flex;
    width: 100%;
}