.c-footer {
    z-index: 2000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #00D4C4;
    color: #FFF;
    height: auto;
    padding: 10px;
    img {
        margin-bottom: 20px;
    }
    ul {
        list-style-type: none;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 5px;
        li {
            margin: 0 10px;
        }
        a {
            color: #FFF;
        }
    }
}