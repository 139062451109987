h1 {
    @media(max-width: 576px) {
        font-size: 1.4rem;
    }
}

h2 {
    @media(max-width: 576px) {
        font-size: 1.3rem;
    }
}

h3 {
    @media(max-width: 576px) {
        font-size: 1.2rem;
    }
}

h4 {
    @media(max-width: 576px) {
        font-size: 1.1rem;
    }
}

h5 {
    @media(max-width: 576px) {
        font-size: 1rem;
    }
}

.lead {
    @media(max-width: 576px) {
        font-size: 1rem;
    }
}

p {
    @media(max-width: 576px) {
        margin-bottom: 1.5rem;
    }
}

.pl-4,
.px-4,
html:not([dir="rtl"]) .pfs-4 {
    @media(max-width: 576px) {
        padding-left: 0 !important;
    }
}

a:hover {
    @media(max-width: 576px) {
        text-decoration: none;
        color: #6548a9;
    }
}