.c-sidebar {
    height: 100%;
}

.c-sidebarStacky {
    top: 140px;
}

.c-sidebar-light {
    @media(max-width: 576px) {
        z-index: 999999;
        overflow: scroll;
    }
}