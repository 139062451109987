.c-headerTt {
    background: #003B4F;
    color: #FFF;
    width: 100%;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    text-align: center;
    font-size: 0.8rem;
    img {
        margin: 0 10px;
        @media(max-width: 576px) {
            margin: 5px 10px;
        }
    }
}