    .c-cardList {
        @media (min-width: 576px) {
            .col-sm-6 {
                max-width: calc(50% - 20px);
            }
        }
        @media (min-width: 768px) {
            .col-md-4 {
                max-width: calc(33.33333333% - 20px);
            }
        }
        @media (min-width: 992px) {
            .col-lg-3 {
                max-width: calc(25% - 20px);
            }
        }
        .c-cardListUl {
            padding: 0;
            list-style-type: none;
            display: flex;
            flex-wrap: wrap;
            >li {
                margin-bottom: 20px;
                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                background: #FFF;
                border-radius: 5px;
                padding: 20px;
                margin-left: 10px;
                margin-right: 10px;
                box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.1);
                >img {
                    margin-bottom: 20px;
                    max-width: 100%;
                    height: 260px;
                    width: auto;
                    object-fit: cover;
                }
                h3 {
                    font-size: 14px;
                }
                p {
                    font-size: 12px;
                }
                i {
                    position: absolute;
                    top: -15px;
                    left: -8px;
                }
                button {
                    margin-top: auto;
                }
            }
        }
    }
    
    .c-cardKiyaku {
        ul {
            padding: 0;
            list-style-type: none;
            display: flex;
            flex-wrap: wrap;
        }
        li {
            margin-bottom: 20px;
            position: relative;
            div {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                background: #FFF;
                border-radius: 5px;
                padding: 20px 20px 0 20px;
                box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.1);
            }
            img {
                margin-bottom: 20px;
                max-width: 100%;
                width: 220px;
            }
            h3 {
                font-size: 18px;
                text-align: center;
                margin-bottom: 10px;
                font-weight: bold;
                span {
                    font-size: 14px;
                    margin-bottom: 5px;
                    display: inline-block;
                }
            }
            p {
                font-size: 14px;
                margin-bottom: 20px;
            }
        }
    }
    
    .c-cardRelief {
        ul {
            padding: 0;
            list-style-type: none;
            display: flex;
            flex-wrap: wrap;
        }
        li {
            margin-bottom: 20px;
            position: relative;
            div {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
            img {
                margin-bottom: 20px;
                max-width: 100%;
                width: auto;
            }
            p {
                font-size: 14px;
                margin-bottom: 20px;
                text-align: center;
            }
        }
    }
    
    .c-cardSpecial {
        ul {
            padding: 0;
            list-style-type: none;
            display: flex;
            flex-wrap: wrap;
        }
        li {
            margin-bottom: 20px;
            position: relative;
            div {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
            img {
                margin-bottom: 20px;
                max-width: 100%;
                width: auto;
            }
            h3 {
                font-size: 14px;
                font-weight: bold;
                text-align: center;
            }
            p {
                font-size: 14px;
                margin-bottom: 20px;
                text-align: center;
            }
        }
    }