.page-item.active .page-link {
    background-color: #003B4F;
    border-color: #003B4F;
}

.page-link {
    color: #003B4F;
}

a {
    color: #003B4F;
}

a:hover {
    text-decoration: none;
    color: #00698c;
}

.btn-primary {
    color: #fff;
    background-color: #003B4F;
    border-color: #003B4F;
}

.table-responsive>.table {
    white-space: nowrap;
}

.c-avatar-img {
    width: 150px;
    height: 150px;
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    border-radius: 50em;
}