.c-listUl {
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    border-radius: 0.25rem;
    list-style-type: decimal;
    @media(max-width: 576px) {
        padding-left: 1rem;
    }
}

.c-listDot {
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    border-radius: 0.25rem;
    list-style-type: disc;
}

.c-listLi {
    padding: 0.5rem 0.25rem;
}