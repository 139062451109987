.c-lavel {
    font-size: 14px;
    line-height: 38px;
    position: relative;
    z-index: 1;
    display: block;
    width: 180px;
    height: 39px;
    transition: .1s ease-in-out;
    text-align: center;
    text-decoration: none;
    color: #000;
    border-top: 2px solid #000;
    border-bottom: 2px solid #000;
    background-color: #FFF;
    margin-bottom: 20px;
}

.c-lavel:before,
.c-lavel:after {
    position: absolute;
    top: 3px;
    width: 30px;
    height: 29px;
    content: '';
    transition: .1s ease-in-out;
    transform: perspective(12px);
    transform: rotate(45deg);
    border: 0;
    background-color: #fff;
}

.c-lavel:before {
    left: -14px;
    border-bottom: 3px solid #000;
    border-left: 3px solid #000;
    border-radius: 5px;
}

.c-lavel:after {
    right: -14px;
    border-top: 3px solid #000;
    border-right: 3px solid #000;
    border-radius: 5px;
}

.c-lavel:hover:before,
.c-lavel:hover:after {
    background-color: #fff;
}

.c-lavel:hover {
    color: #000;
    background-color: #fff;
}